import React, { useState } from "react";
import * as dashboardActions from "../../Redux/Actions/DashboardActions/DashboardActions";
import * as userActions from "../../Redux/Actions/UserActions/UserActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CountUp from "react-countup";
import ModelPopUp from "./ModelPopUp";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Card from "@material-ui/core/Card";
import { useDashboardContent } from "./CustomHooks/useDashboardContent";
import { makeStyles } from "@material-ui/core/styles";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { titleCase } from "../../Utils/titleCaseFunction";
import { Link } from "react-router-dom";
import { ProgressReport } from "./ProgressReport";
import { FacilityPerformance } from "./FacilityPerformance";
import {
  CashPaymentsDashboardTable,
  DebtorsDashboardTable,
  TopSellingProducts,
  DashboardReport,
} from "./DashboardTables";
import { history } from "../../Helpers/history";
import ReusableDashboardHeader from "./ReusableDashboardHeader";
import {
  FaReceipt,
  FaPrescriptionBottleAlt,
  FaCashRegister,
  FaUserPlus,
} from "react-icons/fa";
import { FcComboChart } from "react-icons/fc";
import { convertMonth, dateStringConvert } from "../../Utils/ConvertDate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const MonthPickerComponent = ({
  handleChange,
  wrapper_class_name = "",
  calendar_class_name = "",
  class_name = "",
  name = "",
}) => {
  const [month_value, setStartDate] = useState(new Date());
  return (
    <DatePicker
      selected={month_value}
      onSelect={(e) => handleChange({ name, value: e })}
      onChange={(data) => {
        if (data) {
          console.log("When do we go: ", data);
          setStartDate(data);
        }
      }}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      wrapperClassName={wrapper_class_name}
      calendarClassName={calendar_class_name}
      className={class_name}
      name={name}
    />
  );
};

export const CustomizedTick = (props) => {
  const { x, y, payload } = props;
  const txt = Intl.NumberFormat("en", { notation: "compact" }).format(
    payload.value
  );
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666" style={{ fontWeight: "600" }}>
        <tspan textAnchor="middle" x="-20" y={"-15"}>
          {txt}
        </tspan>
      </text>
    </g>
  );
};

export const CustomTooltip = ({ active, payload, label }) => {
  const fillerStyle = {
    backgroundColor: "#fcfcfd",
    border: "none",
    fontSize: "6px",
    padding: "10px",
    borderRadius: "20px",
    textAlign: "left",
    boxShadow: "0 5px 5px 0 rgba(0, 0, 0, 0.1)",
  };

  const labelStyle = {
    fontFamily: "Visby CF",
    fontStyle: "normal",
    fontWeight: "700",
    color: "#172647",
    fontSize: "11px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px",
  };

  const valueStyle = {
    fontFamily: "Visby CF",
    fontStyle: "normal",
    fontWeight: "700",
    color: "#355AA8",
    fontSize: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "2px",
  };

  const orangeShortBorder = {
    width: "34px",
    borderBottom: "2px solid #F76B1D",
    marginLeft: "2px",
  };

  if (active && payload && payload.length) {
    return (
      <div style={fillerStyle}>
        <p style={labelStyle}>{`${label}`}</p>
        <div style={orangeShortBorder}></div>
        <p
          style={valueStyle}
        >{`${titleCase(payload[0].name.replaceAll("_", " "))} : ${new Intl.NumberFormat("en", { style: "decimal" }).format(payload[0].value)}`}</p>
        <p
          style={valueStyle}
        >{`${titleCase(payload[1].name.replaceAll("_", " "))} : ${new Intl.NumberFormat("en", { style: "decimal" }).format(payload[1].value)}`}</p>
        {payload[2] ? (
          <p
            style={valueStyle}
          >{`${titleCase(payload[2].name.replaceAll("_", " "))} : ${new Intl.NumberFormat("en", { style: "decimal" }).format(payload[2].value)}`}</p>
        ) : null}
      </div>
    );
  }
  return null;
};

export const useStyles = makeStyles(() => ({
  orangeCard: {
    borderLeft: "3px solid #ff7907",
  },
  blueCard: {
    borderLeft: "2px solid #3fb8f1",
  },
  yellowCard: {
    borderLeft: "2px solid #ffc107",
  },
  card: {
    padding: "0.7rem",
    borderRadius: "10px",
    border: "1px solid #f1f2f7",
    marginBottom: "0.6rem",
  },
  card_style: {
    borderRadius: "15px",
    border: 0,
    //   border: '1px solid rgb(223 219 219)',
    padding: "10px",
    boxShadow: "none",
  },
  link_card_style: {
    borderRadius: "15px",
    border: 0,
    padding: "10px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#eef5f8",
    },
  },
  table_card_style: {
    borderRadius: "15px",
    border: 0,
    padding: "10px",
    boxShadow: "none",
    height: "95%",
  },
  small_card_style: {
    borderRadius: "15px",
    border: 0,
    padding: "10px",
    boxShadow: "none",
    textAlign: "center",
    height: "85%",
    "&:hover": {
      backgroundColor: "#eef5f8",
    },
  },
  today_report_style_table: {
    borderRadius: "15px",
    border: 0,
    padding: "15px",
    boxShadow: "none",
    background: "#1290a4",
    color: "white",
    height: "90%",
  },
  tableHeadText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    padding: "8px",
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "-0.3px",
    color: "#8a8a8a",
  },
  tableStyledRow: {
    backgroundColor: "#EFF0F6",
    border: "none",
  },
  tableCellText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    padding: "8px",
    fontSize: "12px",
    lineHeight: "120%",
    letterSpacing: "-0.4px",
    color: "#171717",
  },
  emptytext: {
    textAlign: "center",
  },
  tableContainterStyle: {
    border: "none",
  },
}));

export const getCustomFilterName = (filter_type, custom_filter) => {
  if (filter_type === "custom_day") {
    return dateStringConvert(custom_filter);
  }

  if (filter_type === "custom_month") {
    return convertMonth(custom_filter);
  }

  return "";
};

const filter_names = {
  today: "Today",
  this_week: "This Week",
  this_month: "This Month",
  custom_month: "Custom Month",
  custom_day: "Custom Day",
};

// Component for the cards
const DashboardCards = ({
  card_title,
  card_value,
  is_link = false,
  classes,
  filter_type = "",
  custom_filter = "",
}) => {
  return (
    <Card
      className="mb-3 metrics__card"
      classes={{ root: is_link ? classes.link_card_style : classes.card_style }}
    >
      {filter_type ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p className="dashboard-card-font-color">{card_title}</p>
            <div className="orange-short-border"></div>
          </div>
          {custom_filter ? (
            <div>
              <p className="dropdown-filter">
                {getCustomFilterName(filter_type, custom_filter)}
              </p>
            </div>
          ) : (
            <div>
              <p className="dropdown-filter">{filter_names[filter_type]}</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p className="dashboard-card-font-color">{card_title}</p>
          <div className="orange-short-border"></div>
        </div>
      )}
      <p className="card-value-text">
        <CountUp end={card_value} separator="," duration={10} />
      </p>
    </Card>
  );
};

const DashboardContent = ({ actions, snackbars }) => {
  const {
    openDialog,
    remainingDays,
    openAlert,
    handleChangeDate,
    period,
    year,
    from_date,
    years,
    creditors,
    to_date,
    inputType,
    debtors,
    handleOpenDialog,
    handleCloseDialog,
    handleChangePeriod,
    topSellingProducts,
    handleChangeYear,
    handleCloseBar,
    expiredDrugs,
    outOfStockDrugs,
    customers,
    manufacturers,
    custom_filter,
    products,
    invoices_data,
    reportProps,
    cashPayments,
    progressReport,
    filter_type,
    handleFilterChange,
    loading,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    sales_analytics,
    handleCustomDateChange,
  } = useDashboardContent(actions);
  const { message, open, variant } = snackbars;
  const classes = useStyles();

  const today_date = new Date();
  const time_now = new Intl.DateTimeFormat("default", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  }).format(today_date);

  const sub_heading = `Welcome to Mpeke RX | ${today_date.toDateString()} : ${time_now}`;

  // get the invoices
  const { total_cancelled, total_invoices, total_receipts } = invoices_data;

  return (
    <div style={{ backgroundColor: "#E4E4E4" }}>
      <ReusableDashboardHeader
        component="Dashboard"
        heading="Dashboard"
        dashboard
        link={history.location.pathname}
        subHeading={sub_heading}
      />

      {/* <ModelPopUp openDialog={openDialog} handleOpenDialog={handleOpenDialog} handleCloseDialog={handleCloseDialog}
        expiredDrugs={expiredDrugs} outOfStock={outOfStockDrugs}
      /> */}

      <PharmacySnackbar
        open={open}
        variant={variant}
        message={message}
        handleCloseBar={handleCloseBar}
      />
      {openAlert && (
        <div className="alert alert-danger text-center">
          {remainingDays > 0
            ? `Your license will expire in ${remainingDays} day(s)`
            : "Your License has expired, contact Marascientific to renew it"}
        </div>
      )}

      <div className="dashboard-container-content">
        {/**
         * FIRST ROW
         */}
        <Row>
          <Col lg={6}>
            <Row>
              <Col xs={12} sm={4}>
                <DashboardCards
                  card_title={"Total Customers"}
                  card_value={customers}
                  classes={classes}
                />
              </Col>

              <Col xs={12} sm={4}>
                <DashboardCards
                  card_title={"Total Suppliers"}
                  card_value={manufacturers}
                  classes={classes}
                />
              </Col>

              <Col xs={12} sm={4}>
                <DashboardCards
                  card_title={"Total Medicine"}
                  card_value={products}
                  classes={classes}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={4}>
                <Link to="/out_of_stock" style={{ textDecoration: "none" }}>
                  <DashboardCards
                    card_title={"Out of Stock"}
                    card_value={outOfStockDrugs.length}
                    is_link={true}
                    classes={classes}
                  />
                </Link>
              </Col>

              <Col xs={12} sm={4}>
                <Link to="/expired_drugs" style={{ textDecoration: "none" }}>
                  <DashboardCards
                    card_title={"Expired"}
                    card_value={expiredDrugs.length}
                    is_link={true}
                    classes={classes}
                  />
                </Link>
              </Col>

              <Col xs={12} sm={4}>
                <Link
                  to="/managecustomer/credit_customer"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardCards
                    card_title={"Debtors"}
                    card_value={debtors.length}
                    is_link={true}
                    filter_type={filter_type}
                    classes={classes}
                    custom_filter={custom_filter}
                  />
                </Link>
              </Col>
            </Row>
          </Col>

          <Col sm={12} lg={6}>
            <Card
              className="mb-3 metrics__card"
              classes={{ root: classes.today_report_style_table }}
            >
              <DashboardReport
                reportProps={reportProps}
                filter_type={filter_type}
                handleFilterChange={handleFilterChange}
                filter_names={filter_names}
                handleCustomDateChange={handleCustomDateChange}
                custom_filter={custom_filter}
              />
            </Card>
          </Col>
        </Row>

        {/**
         * SECOND ROW
         */}
        <Row>
          <Col sm={12} lg={6}>
            <Card
              className="p-3 mb-3 metrics__card"
              classes={{ root: classes.table_card_style }}
            >
              <ProgressReport
                {...{
                  period,
                  handleChangeDate,
                  handleChangePeriod,
                  progressReport,
                  handleChangeYear,
                  years,
                  year,
                  inputType,
                  from_date,
                  to_date,
                }}
              />
            </Card>
          </Col>

          <Col>
            <Row>
              <Col md={6}>
                <Row>
                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/new-receipt"
                        style={{ textDecoration: "none" }}
                      >
                        <FaCashRegister
                          color={"#52489C"}
                          className={"dashboard-icons"}
                        />
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Cash Sale
                        </p>
                      </Link>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/createinvoice/new-invoice"
                        style={{ textDecoration: "none" }}
                      >
                        <FaReceipt
                          color={"#CB005F"}
                          className={"dashboard-icons"}
                        />
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Credit Sale
                        </p>
                      </Link>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/addmedicine"
                        style={{ textDecoration: "none" }}
                      >
                        <FaPrescriptionBottleAlt
                          color={"#D00000"}
                          size={"30px"}
                        />
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Add Medicine
                        </p>
                      </Link>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/addcustomer"
                        style={{ textDecoration: "none" }}
                      >
                        <FaUserPlus color={"#FCBD00"} size={"30px"} />
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Add Customer
                        </p>
                      </Link>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/sales_report"
                        style={{ textDecoration: "none" }}
                      >
                        <FcComboChart className="dashboard-icons-chart" />
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Sales Report
                        </p>
                      </Link>
                    </Card>
                  </Col>

                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/accounting/managecreditnotes"
                        style={{ textDecoration: "none" }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          version="1"
                          viewBox="0 0 48 48"
                          enable-background="new 0 0 48 48"
                          height="30px"
                          width="30px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="#B655E4">
                            <polygon points="5,18 19,6.3 19,29.7"></polygon>
                            <path d="M28,14H16v8h12c2.8,0,5,2.2,5,5s-2.2,5-5,5h-3v8h3c7.2,0,13-5.8,13-13S35.2,14,28,14z"></path>
                          </g>
                        </svg>
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Customer Returns
                        </p>
                      </Link>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/purchase_report"
                        style={{ textDecoration: "none" }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          version="1"
                          viewBox="0 0 48 48"
                          enable-background="new 0 0 48 48"
                          height="25px"
                          width="30px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polygon
                            fill="#42213D"
                            points="42,37 6,37 6,25 16,10 30,17 42,6"
                          ></polygon>
                          <polygon
                            fill="#FFBB4E"
                            points="42,42 6,42 6,32 16,24 30,26 42,17"
                          ></polygon>
                        </svg>
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Purchase Report
                        </p>
                      </Link>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <Card
                      className="mb-3 metrics__card"
                      classes={{ root: classes.small_card_style }}
                    >
                      <Link
                        to="/stockreport"
                        style={{ textDecoration: "none" }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          version="1"
                          viewBox="0 0 48 48"
                          enable-background="new 0 0 48 48"
                          class="dashboard-icons-svg"
                          height="40px"
                          width="40px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#4173DD"
                            d="M24,6C14.1,6,6,14.1,6,24s8.1,18,18,18c5.2,0,9.9-2.2,13.1-5.7L24,24V6z"
                          ></path>
                          <path
                            fill="#54D14D"
                            d="M42,24c0-9.9-8.1-18-18-18v18H42z"
                          ></path>
                          <path
                            fill="#B655E4"
                            d="M24,24l13.1,12.3c3-3.2,4.9-7.5,4.9-12.3H24z"
                          ></path>
                        </svg>
                        <p className="dashboard-card-font-color-small dashboard-icons-text">
                          Stock Report
                        </p>
                      </Link>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <DashboardCards
                    card_title={"Creditors"}
                    card_value={creditors}
                    is_link={true}
                    filter_type={filter_type}
                    classes={classes}
                    custom_filter={custom_filter}
                  />
                </Link>

                <DashboardCards
                  card_title={"Total Receipts"}
                  card_value={total_receipts}
                  classes={classes}
                  filter_type={filter_type}
                  custom_filter={custom_filter}
                />

                <DashboardCards
                  card_title={"Total Invoices"}
                  card_value={total_invoices}
                  classes={classes}
                  filter_type={filter_type}
                  custom_filter={custom_filter}
                />

                <DashboardCards
                  card_title={"Total Cancelled"}
                  card_value={total_cancelled}
                  classes={classes}
                  filter_type={filter_type}
                  custom_filter={custom_filter}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/**
         * THIRD ROW
         */}
        <Row>
          <Col sm={12} lg={6}>
            <Card
              className="mb-3 metrics__card"
              classes={{ root: classes.table_card_style }}
            >
              <DebtorsDashboardTable
                {...{
                  debtors,
                  loading,
                  filter_type,
                  filter_names,
                  custom_filter,
                }}
              />
            </Card>
          </Col>

          <Col sm={12} lg={6}>
            <Card
              className="mb-3 metrics__card"
              classes={{ root: classes.table_card_style }}
            >
              <FacilityPerformance sales_analytics={sales_analytics} />
            </Card>
          </Col>
        </Row>

        {/**
         * FOURTH ROW
         */}
        <Row>
          <Col>
            <Card
              className="mb-3 metrics__card"
              classes={{ root: classes.table_card_style }}
            >
              <TopSellingProducts
                filter_type={filter_type}
                topSellingProducts={topSellingProducts}
                handleFilterChange={handleFilterChange}
                filter_names={filter_names}
                custom_filter={custom_filter}
              />
            </Card>
          </Col>

          <Col>
            <Card
              className="mb-3 metrics__card"
              classes={{ root: classes.table_card_style }}
            >
              <CashPaymentsDashboardTable
                {...{ cashPayments, filter_type, filter_names, custom_filter }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    statistics: state.dashboard,
    users: state.users,
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      dashboardActions: bindActionCreators(dashboardActions, dispatch),
      userActions: bindActionCreators(userActions, dispatch),
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContent);
